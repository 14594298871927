(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory((function webpackLoadOptionalExternalModule() { try { return require("moment"); } catch(e) {} }()));
	else if(typeof define === 'function' && define.amd)
		define([], function webpackLoadOptionalExternalModuleAmd() {
			return factory(root["moment"]);
		});
	else if(typeof exports === 'object')
		exports["phoehnlix-frontend"] = factory((function webpackLoadOptionalExternalModule() { try { return require("moment"); } catch(e) {} }()));
	else
		root["phoehnlix-frontend"] = factory(root["moment"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__1632__) => {
return 